h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  p {
    font-size: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 24px;
    }
  
    p {
      font-size: 18px;
    }
  }
  