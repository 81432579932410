.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  color: #fff;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar-links li {
  margin-left: 10px;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Arial', sans-serif;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 10px;
    background-color: #f9f9f9;
    color: #333;
  }

  .navbar-brand {
    margin-bottom: 10px;
    color: #333;
  }

  .navbar-links {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links li {
    margin: 5px 0;
  }

  .navbar-links a {
    color: #333;
  }
}
