.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.heading {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-family: 'Arial', sans-serif;
  font-size: 36px;
}

button {
  background-color: #ffeecc;
  color: #333;
  font-size: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ffd699;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .heading {
    font-size: 24px;
  }
}
