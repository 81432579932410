.emergency-button-container {
    position: fixed;
    bottom: 0px;
    right: 20px;
  }
  
  .emergency-button {
    padding: 10px;
  }
  
  .emergency-options {
    position: absolute;
    bottom: 50px;
    right: 0;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }